<template>
	<ValidationForm
		#default="{ handleSubmit }"
		class="cassie-vertical-md"
	>
		<SystemEmailsLayout>
			<template #content>
				<SectionCard>
					<template #title>
						Details
					</template>
					<template #body>
						<v-row dense>
							<v-col :cols="6">
								<TextField
									v-model="systemEmail.systemEmailName"
									:disabled="systemEmail.readOnly"
									label="System Email Name *"
									rules="required"
								/>
							</v-col>
							<v-col :cols="6">
								<Dropdown
									v-model="systemEmail.senderEmailAddressId"
									:disabled="systemEmail.readOnly"
									:items="senderEmailsMapped"
									label="Sender Email Address *"
									rules="required"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col :cols="6">
								<TextField
									v-model="systemEmail.systemEmailSubject"
									:disabled="systemEmail.readOnly"
									label="System Email Subject *"
									rules="required"
								/>
							</v-col>
							<v-col :cols="6">
								<Dropdown
									v-model="systemEmail.brandId"
									:disabled="systemEmail.readOnly || !clientMultiBranded || singleBrand"
									:items="filteredBrandOptions"
									custom-sort
									:label="`Brand *` | useLabels"
									:rules="{ required: clientMultiBranded}"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col :cols="6">
								<Dropdown
									v-model="systemEmail.emailTypeId"
									:disabled="systemEmail.readOnly"
									:items="transformedEmailTypes"
									label="Email Type *"
									rules="required"
									@change="handleEmailTypeChange"
								/>
							</v-col>
						</v-row>
						<div v-if="availablePlaceholders.length > 0">
							<div class="text-subtitle-2">
								Available Placeholders
							</div>
							<ul>
								<li
									v-for="placeholder in availablePlaceholders"
									:key="placeholder.mergeFieldId"
									class="draggable-placeholder"
									:data-drag="placeholder.placeholderName"
									draggable="true"
									@dragstart="onDragStart($event, placeholder.placeholderName)"
								>
									{{ placeholder.placeholderName }}
								</li>
							</ul>
						</div>
						<Message
							v-if="showDoubleOptInWarning"
							type="warning"
						>
							A Double Opt In System Email must include a Double Opt In Link Placeholder.
						</Message>

						<v-row dense>
							<v-col>
								<FroalaEditor
									ref="froalaEditor"
									v-model="systemEmail.systemEmailHtml"
									title="Email Contents *"
									:rules="{ required: true, max: 2000 }"
								/>
							</v-col>
						</v-row>
						<div class="d-flex cassie-horizontal-md">
							<v-radio-group
								v-model="systemEmail.isPublicPortalConfirmationEmail"
								:disabled="systemEmail.readOnly"
								hide-details
								class="mt-0"
							>
								<template #label>
									Use as the confirmation email sent by Public Portal?
								</template>
								<div class="d-flex">
									<v-radio
										label="Yes"
										:value="true"
										class="my-0 mr-4"
									/>
									<v-radio
										label="No"
										:value="false"
										class="my-0"
									/>
								</div>
							</v-radio-group>
						</div>
					</template>
				</SectionCard>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="navigateToOverview">
							Back to overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							:disabled="systemEmail.readOnly"
							@click="handleSubmit(submit)"
						>
							{{ isEdit ? 'Save' : 'Create' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</SystemEmailsLayout>
	</ValidationForm>
</template>

<script>
import SectionCard from '../../../../../shared/components/section-card.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import FroalaEditor from '../../shared/froala-editor.vue'
import { singleBrand, defaultBrandInSelect, clientMultiBranded, brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM } from '../../../../../shared/state/brands.js'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import PageActionRow from '../../../../../shared/components/page-action-row.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import SystemEmailsLayout from './system-emails-layout.vue'
import { createSystemEmail, updateSystemEmail, getSenderEmailAddresses, getSystemEmails } from '../../../../../shared/utils/api/system-emails.js'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import Message from '../../../../../shared/components/message.vue'

export default {
	components: {
		Dropdown,
		SystemEmailsLayout,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		ValidationForm,
		FroalaEditor,
		TextField,
		SectionCard,
		Message
	},
	props: {
		systemEmailToEdit: Object,
		readOnlyPermissions: Boolean,
		emailTypes: {
			type: Array,
			default: () => []
		}
	},
	setup () {
		return {
			brandOptions,
			clientMultiBranded,
			singleBrand,
			defaultBrandInSelect
		}
	},
	data () {
		return {
			senderEmailItems: [],
			systemEmail: JSON.parse(JSON.stringify(this.systemEmailToEdit || {
				emailId: this.systemEmailId ?? 0,
				systemEmailName: '',
				systemEmailSubject: '',
				senderEmailId: 0,
				systemEmailHtml: '',
				isPublicPortalConfirmationEmail: false,
				isCSPortalConfirmationEmail: false,
				brandId: defaultBrandInSelect.value,
				emailTypeId: null
			})),
			emailPlaceholders: []
		}
	},
	computed: {
		isEdit () {
			return Boolean(this.systemEmailToEdit)
		},
		senderEmailsMapped () {
			return this.senderEmailItems.map(senderEmail => ({
				value: senderEmail.senderEmailAddressId,
				text: senderEmail.fromAddress
			}))
		},
		filteredBrandOptions () {
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		},
		transformedEmailTypes () {
			return this.emailTypes.map(type => ({
				text: type.emailTypeDescription,
				value: type.emailTypeId
			}))
		},
		availablePlaceholders () {
			return this.emailPlaceholders.filter(p => p.emailTypeId === this.systemEmail.emailTypeId)
		},
		showDoubleOptInWarning () {
			return this.systemEmail.emailTypeId === 1 && !/\[(DOUBLE_OPT_IN_LINK|CLICK_TO_CONFIRM_DOUBLE_OPT_IN_LINK)\]/.test(this.systemEmail.systemEmailHtml)
		}
	},
	async created () {
		const { data: senderEmailAddresses } = await getSenderEmailAddresses()
		this.senderEmailItems = senderEmailAddresses

		const { data } = await getSystemEmails()
		this.emailPlaceholders = data.emailPlaceholders
	},
	methods: {
		onDragStart (event, placeholderText) {
			event.dataTransfer.setData('text', placeholderText)
		},
		handleEmailTypeChange () {
			let content = this.systemEmail.systemEmailHtml
			const placeholderRegex = /[[{][A-Z_]+[}\]]/g
			content = content.replace(placeholderRegex, '')
			this.systemEmail.systemEmailHtml = content
		},
		async submit () {
			if (this.showDoubleOptInWarning) {
				showSnackbar({ color: 'red', text: 'Please include a Double Opt-In Link Placeholder before saving.' })
				return
			}

			const systemEmailObject = {
				emailId: this.systemEmail.systemEmailId,
				senderEmailAddressId: this.systemEmail.senderEmailAddressId,
				systemEmailName: this.systemEmail.systemEmailName,
				systemEmailSubject: this.systemEmail.systemEmailSubject,
				systemEmailBody: this.systemEmail.systemEmailHtml,
				isPublicPortalConfirmationEmail: this.systemEmail.isPublicPortalConfirmationEmail,
				isCSPortalConfirmationEmail: this.systemEmail.isCSPortalConfirmationEmail,
				brandId: this.systemEmail.brandId,
				emailTypeId: this.systemEmail.emailTypeId
			}
			if (this.isEdit) {
				await updateSystemEmail(systemEmailObject)
			} else {
				await createSystemEmail(systemEmailObject)
			}

			this.navigateToOverview()
			showSnackbar(this.isEdit ? 'This system email has been updated' : 'You have created a new system email')
		},
		navigateToOverview () {
			// this.$router.push({ name: SYSTEM_EMAILS })
			this.$router.back()
		}
	}
}
</script>
